<script setup lang="ts">
const loginGuard = useLoginGuard();
const { open } = useAppModals();
const { depositInfoData } = useFunzcityDepositStreak();

const handleGiftAction = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "button_right" });
		}
	});
};
</script>

<template>
	<div class="gift-label" @click="handleGiftAction">
		<AText
			v-if="depositInfoData?.canWheel"
			class="text-tlalnepantla text-carabanchel gift-label-counter"
			:modifiers="['bold']"
		>
			1
		</AText>
		<MDepositStreakMiniFerrisWheel />
	</div>
</template>

<style lang="scss" scoped>
.gift-label {
	position: fixed;
	z-index: 1002;
	right: 0;
	bottom: 150px;
	height: 92px;
	width: 46px;
	cursor: pointer;
	overflow: hidden;

	@include media-breakpoint-down(lg) {
		display: none;
	}

	&:before {
		content: "";
		position: absolute;
		z-index: 2;
		background: var(--gondal);
		bottom: 0;
		left: 0;
		width: 100%;
		height: 30px;
	}

	&-counter {
		position: absolute;
		top: 16px;
		left: 4px;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background: var(--сirebon);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1005;
	}

	&-img {
		height: 92px;
		width: 46px;
		object-fit: cover;
	}
}
</style>
